var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Item',{attrs:{"item":_vm.item,"parent":_vm.parent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var shadowVar = ref.shadowVar;
return [_c('div',{staticClass:"w-full",style:(shadowVar)},[(_vm.headlineItem || _vm.introItem)?_c('div',{class:{
        'lg:w-2/3': !_vm.isHalf,
        'mr-auto': _vm.align === 'left',
        'mx-auto': _vm.align === 'center',
        'ml-auto': _vm.align === 'right',
      }},[(_vm.headlineItem)?_c('Headline',{class:_vm.introItem ? 'mb-4' : 'mb-8',attrs:{"item":_vm.headlineItem,"parent":_vm.item}}):_vm._e(),_vm._v(" "),(_vm.introItem)?_c('Intro',{staticClass:"mb-12",attrs:{"item":_vm.introItem,"parent":_vm.item}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c(_vm.isAdminMode ? 'SortableList' : 'div',_vm._b({tag:"Component",staticClass:"grid gap-x-8 md:gap-x-8 gap-y-8",class:[
        _vm.gridClass,
        {
          'justify-center': _vm.item.props.align === 'center',
          'justify-end': _vm.item.props.align === 'right',
        } ],model:{value:(_vm.features),callback:function ($$v) {_vm.features=$$v},expression:"features"}},'Component',_vm.sortableListBindings,false),_vm._l((_vm.features),function(feature,idx){return _c(_vm.isAdminMode ? 'SortableItem' : 'div',{key:feature.uid,tag:"Component",attrs:{"index":idx}},[_c('Feature',{attrs:{"item":feature,"parent":_vm.item}})],1)}),1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }