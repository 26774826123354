<template>
  <Item v-slot="{ shadowVar }" :item="item" :parent="parent">
    <div class="w-full" :style="shadowVar">
      <div
        v-if="headlineItem || introItem"
        :class="{
          'lg:w-2/3': !isHalf,
          'mr-auto': align === 'left',
          'mx-auto': align === 'center',
          'ml-auto': align === 'right',
        }"
      >
        <Headline
          v-if="headlineItem"
          :item="headlineItem"
          :parent="item"
          :class="introItem ? 'mb-4' : 'mb-8'"
        />

        <Intro
          v-if="introItem"
          :item="introItem"
          :parent="item"
          class="mb-12"
        />
      </div>

      <Component
        :is="isAdminMode ? 'SortableList' : 'div'"
        v-model="features"
        :class="[
          gridClass,
          {
            'justify-center': item.props.align === 'center',
            'justify-end': item.props.align === 'right',
          },
        ]"
        v-bind="sortableListBindings"
        class="grid gap-x-8 md:gap-x-8 gap-y-8"
      >
        <Component
          :is="isAdminMode ? 'SortableItem' : 'div'"
          v-for="(feature, idx) in features"
          :key="feature.uid"
          :index="idx"
        >
          <Feature :item="feature" :parent="item" />
        </Component>
      </Component>
    </div>
  </Item>
</template>

<script>
import { dispatch } from 'vuex-pathify'
import blockMixin from '@/mixins/block'
import itemMixin from '@/mixins/item'

export default {
  name: 'Features',

  components: {
    SortableList: () => import('@/components/SortableList'),
    SortableItem: () => import('@/components/SortableItem'),
    Item: () => import('@/components/Item'),
    Feature: () => import('@/components/Items/Feature'),
    Headline: () => import('@/components/Items/Headline'),
    Intro: () => import('@/components/Items/Intro'),
  },

  mixins: [blockMixin, itemMixin],

  computed: {
    headlineItem() {
      return this.item.items.find(item => item.name === 'Headline')
    },

    introItem() {
      return this.item.items.find(item => item.name === 'BodyText')
    },

    sortableListBindings() {
      if (!this.isAdminMode) {
        return
      }

      return {
        'use-drag-handle': true,
        axis: 'xy',
        appendTo: `[data-uid="${this.item.uid}"]`,
      }
    },

    numItems() {
      return this.features.length
    },

    isFullWidth() {
      return this.item.props.width === '1:1'
    },

    isTwoThirds() {
      return this.item.props.width === '2:3'
    },

    gridClass() {
      if (['1:2'].includes(this.item.props.width)) {
        return 'lg:grid-cols-2'
      } else if (this.item.props.width === '1:3') {
        return 'lg:grid-cols-1'
      } else if ([2, 4].includes(this.numItems)) {
        return 'md:grid-cols-2'
      }
      return 'md:grid-cols-2 lg:grid-cols-3'
    },

    features: {
      get() {
        return this.item.items.filter(item => item.name === 'Feature')
      },

      set(val) {
        dispatch('page/update', {
          mutation: 'SET_BLOCK_ITEMS',
          item: { uid: this.item.uid }, // parent
          value: val,
        })
      },
    },
  },
}
</script>

<style></style>
